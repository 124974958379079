<template>
  <div>
    <div v-if="start" class="center">
      <!--    <img :src=urls[0] alt=""> -->
      <h1>Välkommen till plantspelet!</h1>
      <img
        @click="(clicked = true), (unclicked = true)"
        class="beanPic"
        :class="{ beanPic2: !unclicked }"
        src="@/assets/plantassets/Magic_Beans-icon.png"
      />
    </div>
    <!-- <button  @click="showCount">Visa värde!</button> -->
    <div v-if="clicked" class="instructions_and_button">
      <p class="text">
        En morgon när du vaknar ligger det tre gröna, skimrande bönor på ditt
        nattduksbord. Du undrar vad det kan vara för något och bestämmer dig för
        att plantera en av dem i en kruka med lite vanlig blomjord. Efter ett
        par dagar växer det upp en liten grön växt. Nu är det upp till dig vad
        den ska växa upp till. Varje dag kan du, endast en gång, välja om växten
        ska vattnas eller inte och om den ska få se solen eller inte. Du kan
        också välja att prata med din planta. När du är klar klickar du på månen för att komma till nästa dag.
      </p>
      <p class="text">För att vattna din växt: klicka på vattendroppen</p>
      <p class="text">För att ställa ut din växt i solen: klicka på solen</p>
      <p class="text">För att prata med din växt: klicka på pratbubblan</p>
      <button
        v-on:click="(day = true), (clicked = false), (start = false)"
        class="button-36"
        role="button"
      >
        Jag fattar, leggo!
      </button>
    </div>
    <!-- Hela spelet -->
    <div v-if="day">
      <div>
        <img class="moon" @click="lalala" src="@/assets/plantassets/moon.png" />
      </div>
      <h1>Dag: {{ dagar }}</h1>
      <div class="row">
        <div class="column">
          <img
            @click="vattenclick"
            src="@/assets/plantassets/vattnet.png"
            alt="Snow"
            class="vatten"
            :class="{ shake: vattenskak, vattenredo: !vattenskak }"
          />
        </div>
        <div class="column">
          <img
            @click="solclick"
            src="@/assets/plantassets/solen.png"
            alt="Forest"
            class="sol"
            :class="{ shake: solskak, solredo: !solskak }"
          />
        </div>
        <div class="column">
          <img
            @click="pratclick"
            src="@/assets/plantassets/prat.png"
            alt="Mountains"
            class="prat"
            :class="{ shake: pratskak, pratredo: !pratskak }"
          />
        </div>
      </div>
      <div v-if="dagar <= 5" class="pot1">
        <img src="@/assets/plantassets/kruka.png" />
      </div>
      <div v-if="dagar >= 6" class="pot2">
        <img src="@/assets/plantassets/kruka2.png" />
      </div>
    </div>
    <div v-if="night" class="center">
      <h1>God natt!</h1>
      <p>Antal gånger plantan har vattnats: {{ vatten }}</p>
      <p>Antal gånger plantan har sett solen: {{ sol }}</p>
      <p>Antal gånger plantan har blivit pratad med: {{ prat }}</p>
      <p>Sov för att komma till dag {{ dagar }}</p>
      <div class="center">
        <img @click="moon" src="@/assets/plantassets/sleep.png" class="sleep" />
      </div>
    </div>
    <!-- PROBLEM! -->
    <div v-if="gameOver" class="center">
      <h1>Grattis! Du har klarat spelet. Här är din planta</h1>
      <div class="finalplant">
        <img :src="showplant()" />
      </div>
      <div>
        <button
          onclick="document.location.reload()"
          class="button-36"
          role="button"
        >
          Spela igen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plantspelet',
  data: () => ({
    counter: 0,
    clicked: false,
    start: true,
    day: false,
    night: false,
    dagar: 1,
    vatten: 0,
    sol: 0,
    prat: 0,
    vattenhet: true,
    solhet: true,
    prathet: true,
    gameOver: false,
    unclicked: false,
    vattenskak: false,
    solskak: false,
    pratskak: false,
    urls: [
      require('@/assets/plantassets/arg-chili.png')
    ],
    finalplants: ['0,0,0', require('@/assets/plantassets/arg-chili.png'),
      '0,0,10', require('@/assets/plantassets/ledsen-vissen-planta.png'),
      '0,10,0', require('@/assets/plantassets/cacti.png'),
      '10,0,0', require('@/assets/plantassets/seaweed.png'),
      '0,10,10', require('@/assets/plantassets/tupptupp.png'),
      '10,0,10', require('@/assets/plantassets/rose.png'),
      '10,10,0', require('@/assets/plantassets/water-lily.png'),
      '10,10,10', require('@/assets/plantassets/beanstalk.png'),
      '5,5,5', require('@/assets/plantassets/kruka3.png')]
  }),

  methods: {
    showCount () {
      console.log('counter: ' + this.counter)
    },
    vattenclick () {
      if (this.vattenhet) {
        this.vatten += 1
        this.vattenhet = false
        this.vattenskak = true
      }
    },
    solclick () {
      if (this.solhet) {
        this.sol += 1
        this.solhet = false
        this.solskak = true
      }
    },
    pratclick () {
      if (this.prathet) {
        this.prat += 1
        this.prathet = false
        this.pratskak = true
      }
    },
    lalala () {
      if (this.dagar < 10) {
        this.dagar += 1
        this.vattenhet = true
        this.solhet = true
        this.prathet = true
        this.day = false
        this.night = true
        console.log('VATTEN: ' + this.vatten + ', SOL: ' + this.sol + ', PRAT: ' + this.prat)
      } else {
        this.day = false
        this.gameOver = true
        console.log('VATTEN: ' + this.vatten + ', SOL: ' + this.sol + ', PRAT: ' + this.prat)
      }
    },
    moon () {
      this.day = true
      this.night = false
      this.vattenskak = false
      this.solskak = false
      this.pratskak = false
    },
    showplant () {
      this.key = this.calculate()
      var plantbild = 1
      if (this.finalplants.includes(this.key)) {
        for (let i = 0; this.finalplants[i] !== this.key; i++) {
          plantbild = i + 2
          if (i > this.finalplants.length) { break }
        }
        return this.finalplants[plantbild]
      } else {
        if (this.vatten === this.sol && this.sol === this.prat) {
          console.log('boring')
          return require('@/assets/plantassets/boring.png')
        } else if (this.prat >= this.sol && this.sol > this.vatten) {
          console.log('kött')
          return require('@/assets/plantassets/carnivorus.png')
        } else if (this.vatten >= this.prat && this.prat > this.sol) {
          console.log('ormbunke')
          return require('@/assets/plantassets/ormbunke.png')
        } else if (this.sol >= this.vatten && this.vatten > this.prat) {
          console.log('solros')
          return require('@/assets/plantassets/Sunflower.png')
        } else if (this.sol > this.prat && this.prat >= this.vatten) {
          console.log('sukulent')
          return require('@/assets/plantassets/sukulent.png')
        } else if (this.prat > this.vatten && this.vatten >= this.sol) {
          console.log('palettblad')
          return require('@/assets/plantassets/palettblad.png')
        } else if (this.vatten > this.sol && this.sol >= this.prat) {
          console.log('bambooo')
          return require('@/assets/plantassets/bambooo.png')
        }
      }
      console.log('plantbild: ' + plantbild)
    },
    calculate () {
      var key = String([this.vatten, this.sol, this.prat])
      console.log('key: ' + key)
      return key
    }
  }
}
</script>

<style scoped>
/* body {
  background: #f3b434;
} */
/* img {
  height: 27%;
  width: 27%;
} */

.center {
  margin: auto;
  width: 70%;
  padding-top: 3%;
  font-size: 130%;
  text-align: center;
}

.text {
  margin: auto;
  width: 70%;
  font-size: 110%;
  text-align: center;
}

/* Mobil / Portrait */
@media screen and (orientation: portrait) {
  .beanPic {
    width: 75%;
    height: 75%;
  }
  .prat {
    width: 15%;
    height: 80%;
  }
  .sol {
    width: 15%;
    height: 80%;
  }
  .vatten {
    width: 15%;
    height: 70%;
  }
  .moon {
    float: right;
    top: 1.6%;
    right: 1%;
    padding-top: 0.5%;
    padding-right: 0.5%;
    width: 12%;
    height: 24%;
  }
  .pot1 {
    position: absolute;
    padding-top: 5%;
    top: 62%;
    right: -22.5%;
    width: 90%;
  }
  .pot2 {
    position: absolute;
    padding-top: 5%;
    top: 62%;
    right: -18%;
    width: 90%;
  }
  /* Three image containers (use 25% for four, and 50% for two, etc) */
  .column {
    float: left;
    width: 100%;
    padding: 2%;
    text-align: center;
  }
}
/* Dator / Landscape */
@media screen and (orientation: landscape) {
  .beanPic {
    width: 30%;
    height: 30%;
  }
  .prat {
    padding-top: 6%;
    width: 50%;
    height: 90%;
  }
  .sol {
    padding-top: 6%;
    width: 50%;
    height: 90%;
  }
  .vatten {
    padding-top: 6%;
    width: 40%;
    height: 90%;
  }
  .moon {
    float: right;
    top: 5%;
    right: 3%;
    padding-top: 0.5%;
    padding-right: 0.5%;
    width: 6%;
    height: 12%;
  }
  .pot1 {
    position: absolute;
    padding-top: 5%;
    top: 39%;
    right: -28.5%;
    width: 90%;
  }
  .pot2 {
    position: absolute;
    padding-top: 5%;
    top: 39%;
    right: -26%;
    width: 90%;
  }
  /* Three image containers (use 25% for four, and 50% for two, etc) */
  .column {
    float: left;
    width: 32%;
    padding-top: 4%;
    text-align: center;
  }
}

.beanPic2:hover {
  opacity: 0.8;
}

.instructions_and_button {
  text-align: center;
}

.finalplant {
  padding: 2%;
  width: 100%;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}
.vattenredo:hover {
  opacity: 0.8;
}
.solredo:hover {
  opacity: 0.8;
}
.pratredo:hover {
  opacity: 0.8;
}
.moon:hover {
  opacity: 0.8;
}
.sleep:hover {
  opacity: 0.8;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.button-36 {
  background: #f3b434;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: #f3b434 0 1px 30px;
  transition-duration: 0.1s;
}

@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}
</style>
